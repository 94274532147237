import React, { useEffect, useState } from "react";
import Unity, { UnityContent } from "react-unity-webgl";
import { useHistory, Link } from "react-router-dom";
import {isMobile, browserName} from 'react-device-detect';

import MannageVR from "../../Views/MannageVR/MannageVR";

import SVGUSER from "./../../Assets/Images/user.svg";
import SVGAGENT from "./../../Assets/Images/call-center.svg";

import Logohoyos from "./../../Assets/Images/new-logo-header.png";

//logos patrocinadores
import Logo1 from "./../../Assets/Images/logos/abracol.png";
import Logo2 from "./../../Assets/Images/logos/black.png";
import Logo3 from "./../../Assets/Images/logos/crescent.png";
import Logo4 from "./../../Assets/Images/logos/dewalt.png";
import Logo5 from "./../../Assets/Images/logos/elite.png";
import Logo6 from "./../../Assets/Images/logos/fluid.png";
import Logo7 from "./../../Assets/Images/logos/grival.png";
import Logo8 from "./../../Assets/Images/logos/irwin.png";
import Logo9 from "./../../Assets/Images/logos/lufkin.png";
import Logo10 from "./../../Assets/Images/logos/sata.png";
import Logo11 from "./../../Assets/Images/logos/stanley.png";
import Logo12 from "./../../Assets/Images/logos/udukes.png";
import Logo13 from "./../../Assets/Images/logos/ideace.png";
import Logo14 from "./../../Assets/Images/logos/kontiki.jpg";
import Logo15 from "./../../Assets/Images/logos/ideace2.png";
import Logo16 from "./../../Assets/Images/logos/ideace3.png";
import Logo17 from "./../../Assets/Images/logos/ideace4.png";

const unityContent = new UnityContent(
  process.env.PUBLIC_URL + "/Aplication/Build Final.json",
  process.env.PUBLIC_URL + "/Aplication/UnityLoader.js"
);

export default function UnityView() {
  const [progressUnity, setProgressUnity] = useState(0);
  const [stateUnity, setStateUnity] = useState(false);
  const [typeUser, setTypeUser] = useState(
    localStorage.getItem("hType") || "2"
  );
  const [TypeDelivery, setTypeDelivery] = useState(
    localStorage.getItem("deliveryType") || "1"
  );
  const [auxTypeDelivery, setAuxTypeDelivery] = useState(
    localStorage.getItem("deliveryType") || "1"
  );

  let history = useHistory();

  useEffect(() => {
    console.log("location",window.location)
    if(["Edge", "Firefox", "Chrome", "Safari"].indexOf(browserName ) == -1 ){
      history.push(`/movil`);
    }
    if (isMobile) {
      history.push(`/movil`);
    }
  }, []);

  const handleSubmit = (e) => {
    setTypeDelivery(auxTypeDelivery);
    localStorage.setItem("deliveryType", auxTypeDelivery);
    console.log("holi", auxTypeDelivery);
    //e.preventDefault();
  };

  const handleChangeInput = (e) => {
    const inputValue = e.target.value;
    console.log(inputValue);
    setAuxTypeDelivery(inputValue);
  };

  const sendToken = async () => {
    const tokenLocalStorage = localStorage.getItem("hotok");
    if (tokenLocalStorage) {
      console.log("token", tokenLocalStorage);
      unityContent.send("TourManager", "setToken", tokenLocalStorage);
      await verifyToken(tokenLocalStorage);
    } else {
      loginInvited();
    }
  };
  const verifyToken = async (token) => {
    console.log(token);
    var formData = new FormData();

    formData.append("codigos", "1663B");
    formData.append("tipoEntrega", "1");

    const request = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    fetch(
      "https://ferreteriaonlinehoyostools.com/admin/frontend/web/index.php/cart/is-ok-vr",
      request
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("holi", data);
        if (data.error) {
          console.log("entre");
          //unityContent.send("TourManager", "setToken", token);
          loginInvited();
        } else {
        }
      })
      .catch(() => {
        history.push(`/login`);
      });
  };

  const loginInvited = async () => {
    const request = {
      method: "POST",
    };

    await fetch(
      "https://ferreteriaonlinehoyostools.com/admin/frontend/web/index.php/security/generate-token",
      request
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(!data.error);
        if (!data.error) {
          const token = data.token;
          localStorage.setItem("hotok", token);
          localStorage.removeItem("hoN");
          localStorage.setItem("hType", "2");
          unityContent.send("TourManager", "setToken", token);
          setTypeDelivery(auxTypeDelivery);
          localStorage.setItem("deliveryType", auxTypeDelivery);
          //unityContent.send("TourManager", "setToken", token);
        } else {
          alert(`${data.message}`);
          history.push(`/login`);
        }
      })
      .catch((error) => {
        history.push(`/login`);
      });
  };

  const sendDeliveryType = () => {
    const deliveryTypeLocalStorage =
      localStorage.getItem("deliveryType") || "1";
    unityContent.send(
      "TourManager",
      "setTipoEntrega",
      deliveryTypeLocalStorage
    );
  };

  useEffect(() => {
    if (stateUnity) {
      //console.log("URL",window.location.host)
      sendToken();
      sendDeliveryType();
    }
  }, [stateUnity]);

  useEffect(() => {
    localStorage.setItem("deliveryType", TypeDelivery || 1);
    if (stateUnity) {
      unityContent.send("TourManager", "setTipoEntrega", TypeDelivery || 1);
    }
  }, [TypeDelivery]);

  unityContent.on("loaded", () => {
    // Now we can for example hide the loading overlay.
    setStateUnity(true);
    unityContent.send("TourManager", "setTipoEntrega", TypeDelivery || 1);
  });


  unityContent.on("progress", (progression) => {
    // Now we can use the progression to for example
    // display it on our React app.
    setProgressUnity(Math.round(progression * 100));
  });

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-md-10">
          <div className="container-fluid mt-3">
            {/*<div className="row mb-2">
              <div className="col-md-12 text-center mb-3" >
                <Link  to="/mannagevr" > 
                  ¿Como navegar en la experiencia?
                </Link>
              </div>
            </div>*/}
            <div className="row">
              
              <div className="col-md-3 col-sm-4 order-sm-2 order-md-0 d-flex justify-content-center">
                <a href="https://ferreteriaonlinehoyostools.com/#!/app/home">
                  <img
                    src={Logohoyos}
                    alt="usuario"
                    width="150"
                    height="35"
                    className="pr-2"
                  />
                </a>
              </div>

              <div className="col-md-3 order-sm-3 order-md-1 d-flex">
                {typeUser === "1" && (
                  <button
                    type="button"
                    className="btn btn-primary btn-block btn-orange mb-2"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    disabled={typeUser !== "1"}
                  >
                    <b>Cambiar tipo de entrega</b>
                  </button>
                )}
              </div>

              <div className="col-md-3 col-sm-4 order-sm-0 order-md-2 d-flex justify-content-end">
                <div className="d-flex pb-2" style={{ fontSize: 10 }}>
                  <img
                    src={SVGAGENT}
                    alt="usuario"
                    width="50"
                    className="pr-2 border-right"
                  />
                  <div className="ml-2">
                    <p className="mb-0  text-muted" >
                      Línea de atención nacional
                    </p>
                    <p className="mb-0 text-muted">01 8000 180 292</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-4 order-sm-1 order-md-3">
                <div className="d-flex pb-2" style={{ fontSize: 10 }}>
                  <img
                    src={SVGUSER}
                    alt="usuario"
                    width="50"
                    className="pr-2 border-right"
                  />
                  <div className="ml-2 ">
                    <p className="mb-0 text-dark">Bienvenido</p>
                    <p className="mb-0 text-secondary">
                      <b>{localStorage.getItem("hoN") || "Invitado"}</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <form /* onSubmit={handleSubmit} */ className="modal-content">
                <div className="modal-header btn-orange">
                  <h5 className="modal-title text-white" id="exampleModalLabel">
                    Seleccione el tipo de entrega
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p className="text-muted">
                    El tipo de entrega influye en la disponibilidad de los
                    productos.
                  </p>
                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">
                      Tipo de entrega
                    </label>
                    <select
                      className="form-control"
                      name="deliveryType"
                      onChange={handleChangeInput}
                      id="exampleFormControlSelect1"
                    >
                      <option value="1">Entrega a Domicilio</option>
                      <option value="2">
                        Recojo en punto de venta principal
                      </option>
                    </select>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    cerrar
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary btn-orange shadow-sm"
                    onClick={handleSubmit}
                    data-dismiss="modal"
                  >
                    Guardar Cambio
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="row d-flex flex-column align-items-center justify-content-center">
        
        <div className="col-md-10 d-flex justify-content-center align-items-center" style={{height:"37em", maxHeight:"79vh"}}> {/*style={{ height: "38em", border:"2rem outset #029AA8", padding:0, margin:".5rem 0"}}*/}
          <Unity unityContent={unityContent} className="border" />
          <div className="row justify-content-center" style={{position:"absolute"}}>
            <div className="col-md-10" style={{width:"50vw"}}>
                {progressUnity >= 100 ? (
                  ""
                ) : (
                  <div className="d-flex flex-column justify-content-center ">
                    <p className="text-light h5 text-center">Estamos descargando los datos necesarios</p>

                    <div className="progress mt-2 d-flex flex-column">
                      <div
                        className="progress-bar font-weight-bold"//bg-danger"
                        role="progressbar"
                        style={{ width: `${progressUnity}%`, backgroundColor:"#f58634" }}
                        aria-valuenow={progressUnity}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >{`${progressUnity}%`}</div>
                    </div>
                  </div>
                  
                )}
            </div>
          </div>
          
        </div>
        
        <div className="row align-self-center w-100 d-flex justify-content-center">
          <div className="col-md-10 mt-2" >
            <Link  data-toggle="modal" data-target=".bd-example-modal-lg"> 
              ¿Como navegar en la experiencia?
            </Link>
          </div>
        </div>
      </div>

      <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" data-backdrop="static" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" >
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="exampleModalLongTitle">Como vivir una Experiencia Virtual 360</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" style={{maxHeight:"70vh", overflowY: "auto"}}>
              <MannageVR></MannageVR>
            </div>
            <div class="modal-footer">
                <Link className="btn btn-primary btn-orange" data-dismiss="modal">
                  Regresar a la experiencia virtual
                </Link>
              </div>
          </div>
        </div>
      </div>
      
    </>
  );
}
