import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Background from "../../Assets/Images/hoyostoolsBackground.png";
import HeaderLogo from "../../Assets/Images/new-logo-header.png";

export default function Login() {
  const [User, setUser] = useState({
    username: "",
    password: "",
    promotionalcode: "",
  });

  const history = useHistory();

  function handleSubmit(e) {
    e.preventDefault();
    login(e);
  }

  const login = async (event) => {
    let formData = new FormData();

    /* Object.entries(User).map(([key, value]) => {
			formData.append(key, value);
			return null;
		}); */

    for (var k in User) {
      formData.append(k, User[k]);
    }

    const request = {
      method: "POST",
      body: formData,
    };

    await fetch(
      "https://ferreteriaonlinehoyostools.com/admin/frontend/web/index.php/login/user-login-vr",
      request
    )
      .then((response) => response.json())
      .then((data) => {
        if (!data.error) {
          localStorage.setItem("hotok", data.data.token);
          localStorage.setItem("hoN", data.data.fullname);
          localStorage.setItem("hType", "1");
          history.push("/SelectType");
        } else {
          alert(`${data.message}`);
        }
      })
      .catch((error) => {
        alert("Error al realizar la petición" + error);
      });

    setUser({
      username: "",
      password: "",
      promotionalcode: "",
    });
  };

  const loginInvited = async () => {
    const request = {
      method: "POST",
    };

    await fetch(
      "https://ferreteriaonlinehoyostools.com/admin/frontend/web/index.php/security/generate-token",
      request
    )
      .then((response) => response.json())
      .then((data) => {
        if (!data.error) {
          localStorage.setItem("hotok", data.token);
          localStorage.setItem("deliveryType", "2");
          localStorage.setItem("hType", "2");
          localStorage.removeItem("hoN");

          history.push("/vr");
        } else {
          alert(`${data.message}`);
        }
      })
      .catch((error) => {
        alert("Error al realizar la petición" + error);
      });

    setUser({
      username: "",
      password: "",
      promotionalcode: "",
    });
  };

  function handleChangeInput(e) {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    setUser({ ...User, [inputName]: inputValue });
  }

  return (
    <div className="container-fluid vh-100">
      <div className="row h-100">
        <div
          className="col-md-7 d-sm-none d-none d-md-block p-0 h-100"
          style={{
            backgroundImage: `url(${Background})`,
            backgroundColor: "#fff",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        ></div>
        <div className="col-md-5 col-xs-12 d-flex align-items-center border shadow pr-0 bg-white">
          <div className="card bg-white border-0 w-100">
            <div className="card-header bg-white d-flex flex-column align-items-center justify-content-center bg-white border-bottom-0">
              <img src={HeaderLogo} alt="" width="300" />
              <h5 className="mt-4">Acceso Usuarios Registrados</h5>
              <p className="text-center mb-0">
                Por favor ingrese sus datos de acceso
              </p>
            </div>
            <div className="card-body bg-white">
              <p className="text-danger">*Obligatorio</p>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="usuario">
                    Usuario <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="usuario"
                    aria-describedby="emailHelp"
                    name="username"
                    placeholder="correo@correo.com"
                    value={User.username}
                    onChange={handleChangeInput}
                    required
                  />
                  <div className="valid-feedback">Tiene buena pinta!</div>
                  <div className="invalid-feedback">
                    Por favor, elija un nombre de usuario valido.
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="Password">
                    Contraseña <span className="text-danger">*</span>
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    value={User.password}
                    id="Password"
                    placeholder="⁕⁕⁕"
                    onChange={handleChangeInput}
                    required
                  />
                  <div className="valid-feedback">Tiene buena pinta!</div>
                  <div className="invalid-feedback">
                    Error en la contraseña.
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="Password">Código Promocional</label>
                  <input
                    type="password"
                    className="form-control"
                    name="promotionalcode"
                    id="promotionalcode"
                    placeholder="1A578"
                    value={User.promotionalcode}
                    onChange={handleChangeInput}
                  />
                  <small id="codeHelp" className="form-text text-muted">
                    Si tienes un código promocional, ingresalo en esta casilla.
                  </small>
                </div>
                <button
                  type="submit"
                  name="login"
                  className="btn btn-primary btn-block btn-lg my-4 btn-orange"
                >
                  <b>Ingresar</b>
                </button>
                <button
                  onClick={loginInvited}
                  name="invited"
                  className="btn btn-secondary btn-block my-4 "
                >
                  Ingresar como invitado
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
