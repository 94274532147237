import React from "react";
import UnityView from "../../Components/Unity/UnityView";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <>
      {/*<nav className="navbar navbar-expand-lg navbar-dark bg-dark sticky-top">
        <Link className="navbar-brand" to="/">
          Virtual Reality Shop
        </Link>
        <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
          <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
            <li className="nav-item active">
              <a
                className="nav-link"
                //onClick={() => window.location.replace}
                href="https://ferreteriaonlinehoyostools.com/#!/app/home"
                target="_blank"
              >
                Pagina Inicial
              </a>
            </li>
          </ul>
        </div>
  </nav>*/}
      <div style={{ borderTop: "4px solid #8e8e8e", height:"34px", backgroundColor:"#f58634"}}></div>
      <div className="container-fluid">
        <UnityView />
        <br />
      </div>
    </>
  );
}
