import React from "react";
import { Link } from "react-router-dom";

export default function Error404() {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark sticky-top">
        {/* <Link className="navbar-brand" to="/vr">
          Virtual Reality Shop
        </Link> */}
        <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
          <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
            <li className="nav-item active">
              <a
                className="nav-link"
                //onClick={() => window.location.replace}
                href="https://ferreteriaonlinehoyostools.com/#!/app/home"
                target="_blank"
              >
                Pagina Inicial
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <div className="container-fluid vh-75">
        <div className="row h-100">
          <div className="col-12 h-100 d-flex flex-column justify-content-center align-items-center">
            <h3>Error al encontrar la pagina</h3>
            <img
              width="300"
              src={`${process.env.PUBLIC_URL}/img/404-error.svg`}
              alt="error 404"
            />
          </div>
        </div>
      </div>
    </>
  );
}
