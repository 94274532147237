import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

export default function MannageVR() {
  let history = useHistory();
  const [TypeDelivery, setTypeDelivery] = useState(1);
  return (
    <>
      {/*<nav className="navbar navbar-expand-lg navbar-dark bg-dark sticky-top">
        <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
          <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
            <li className="nav-item active">
              <a
                className="nav-link"
                //onClick={() => window.location.replace}
                href="https://ferreteriaonlinehoyostools.com/#!/app/home"
                target="_blank"
              >
                Pagina Inicial
              </a>
            </li>
          </ul>
        </div>
  </nav>*/}
      <div className="container">
        <div className="row">
          <div className="col-md-12 ">
            {/*<h2>Como vivir una Experiencia Virtual 360</h2>*/}
            <div className="pl-4">
              <h4>
                <b>Paseando por cada uno de los pasos</b>
              </h4>
              <ul style={{ listStyleType: "decimal", textAlign: "justify" }}>
                <li>
                  Dar clic sobre cualquiera de los pasos que contienen el logo
                  de Hoyostools
                </li>
                <li>
                  Una vez ubicado en el paso seleccionado, con clic sostenido y
                  movimiento hacia los lados del mouse podrás tener la visual
                  que desees.
                </li>
                <li>
                  Al ubicar el mouse sobre la imagen deseada podrás dar clic y
                  aparecerá la información correspondiente al producto
                </li>
                <li>
                  Si deseas agregar a tu canasta, solo debes de digitar la
                  cantidad a comprar y dar clic sobre el botón Adicionar. Nota :
                  En la parte inferior Izquierda podrás dar clic para subir o
                  bajar de Piso
                </li>
              </ul>
              <h4>
                <b>Usando el Buscador de Productos</b>
              </h4>
              <ul style={{ listStyleType: "decimal" }}>
                <li>
                  Digita sobre le buscador el nombre del producto que deseas y
                  luego dar enter.
                </li>
                <li>
                  El sistema te traerá los productos que coinciden con tu
                  criterio de búsqueda, puedes dar clic sobre el producto
                  deseado.
                </li>
                <li>
                  Si deseas agregar a tu canasta, solo debes de digitar la
                  cantidad a comprar y dar clic sobre el botón Adicionar.
                </li>
                <li>
                  En la imagen de segundo plano observaras como el sistema te
                  llevara hasta la ubicación del producto dentro de la
                  experiencia virtual. Da clic en la X de la ventana del
                  producto y da un giro 360 grados para que observes el ítem que
                  acabas de buscar. Nota: El paso 1 puedes también buscar por
                  código ítem o por código de barras
                </li>
              </ul>
              <h4>
                <b>Usando las Categorías </b>
              </h4>
              <ul style={{ listStyleType: "decimal" }}>
                <li>
                  Al dar clic sobre los cuadritos en la parte inferior derecha
                  de la experiencia Virtual aparecerán las categorías, al dar
                  clic sobre alguna de ellas el sistema te llevara a la zona de
                  la categoría.
                </li>
              </ul>
              <p>
                Si ya tienes en tu carrito de compras todos los productos que
                deseas comprar solo debes dar clic sobre el icono del carrito de
                compras ubicado en la parte superior derecha, al dar clic sobre
                dicho carrito podrás anular o modificar las cantidades del
                producto. Si estas de acuerdo con la compra solo debes de dar
                clic en el icono llamado Ir al Carrito, y el sistema te llevara
                a la canasta del pedido para Enviar el pedido a tu asesor.
              </p>
              
              {/*<Link className="btn btn-primary btn-orange" data-dismiss="modal">
                Regresar a la experiencia virtual
</Link>*/}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
