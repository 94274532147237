import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

export default function Token() {
  let { token, deliveryType } = useParams();
  let history = useHistory();

  useEffect(() => {
    localStorage.setItem("hotok", token);
    localStorage.setItem("deliveryType", deliveryType || 1);
    history.push(`/vr`);
  }, [history, token, deliveryType]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div class="spinner-border text-danger" role="status">
            <span class="sr-only">Cargando...</span>
          </div>
          <h1>Redireccionando</h1>
        </div>
      </div>
    </div>
  );
}
