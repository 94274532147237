import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

export default function Error404() {
  let history = useHistory();
  const [TypeDelivery, setTypeDelivery] = useState(1);

  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem("deliveryType", TypeDelivery);
    history.push("/vr");
  };

  const handleChangeInput = (e) => {
    const inputValue = e.target.value;
    setTypeDelivery(inputValue);
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark sticky-top">
        <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
          <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
            <li className="nav-item active">
              <a
                className="nav-link"
                //onClick={() => window.location.replace}
                href="https://ferreteriaonlinehoyostools.com/#!/app/home"
                target="_blank"
              >
                Pagina Inicial
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 d-flex flex-column justify-content-center align-items-center">
            <div className="card mt-5">
              <div className="card-body">
                <div className="card-body">
                  <h3 className="card-title">Seleccione el tipo de entrega</h3>
                  <p className="text-muted">
                    El tipo de entrega influye en la disponibilidad de los
                    productos.
                  </p>
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label htmlFor="exampleFormControlSelect1">
                        Tipo de entrega
                      </label>
                      <select
                        className="form-control"
                        name="deliveryType"
                        onChange={handleChangeInput}
                        id="exampleFormControlSelect1"
                      >
                        <option value="1">Entrega a Domicilio</option>
                        <option value="2">
                          Recojo en punto de venta principal
                        </option>
                      </select>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary btn-lg btn-block btn-orange"
                    >
                      Ir a la experiencia virtual
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
