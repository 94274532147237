import React from "react";
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Home from "./Views/Home/Home";
import Token from "./Views/Token/Token";
import Login from "./Views/Login/Login";
import Error404 from "./Views/404/404";
import SelectType from "./Views/SelectType/SelectType";
import MannageVR from "./Views/MannageVR/MannageVR";
function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/vr">
          <Home />
        </Route>
        <Route exact path="/:token/:deliveryType">
          <Token />
        </Route>
        <Route exact path="/Login">
          <Login />
        </Route>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
        <Route exact path="/SelectType">
          <SelectType />
        </Route>
        <Route exact path="/mannagevr">
          <MannageVR />
        </Route>
        <Route exact path='/movil' component={() => { 
            console.log(window.location)
            window.location.href = 'https://showferretero.scenariovr.co/movil/';//'https://'+window.location.origin + '/movil/'; 
        }}/>
        <Route path="*">
          <Error404 />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
